/* eslint-disable */
export const clean_response_data = (data, options) => {
	if (options.type && options.type === 'stories') {
		data.forEach(story => {
			story.url = story.url.replace('.aspx', '');
		});
		return data;
	}
	return data;
};

export const create_url_params_from_object = params_obj => {
	let param_string = '';

	Object.keys(params_obj).forEach((key, index) => {
		param_string += index === 0 ? '?' : '&';

		param_string += `${key}=${encodeURIComponent(params_obj[key])}`;
	});

	return param_string;
};

export const get_image_resizer_url = image_url => {
	const encodedImageUrl = encodeURIComponent(image_url);
	/*
	 * Use a large width so that the image isn't actually resized.
	 * The webp conversion is what we're after here.
	 * (I tried /zoom with factor=1, but it doesn't compress nearly as much.)
	 */
	return `https://images.sidearmdev.com/convert?url=${encodedImageUrl}&type=webp`;
};

export const get = (
	url = '',
	options = {},
	callback = data => {
		return data;
	},
) => {
	return new Promise((resolve, reject) => {
		const param_string = create_url_params_from_object(options);

		fetch(url + param_string)
			.then(response => response.json())
			.then(response => {
				if (response.error) {
					reject(response.error);
					return;
				}

				if (response.data) {
					resolve(
						callback(clean_response_data(response.data, options)),
					);
					return;
				}

				resolve(callback(clean_response_data(response, options)));
			})
			.catch(response => {
				if (response.error) {
					reject(response.error);
					return;
				}

				reject(response);
			});
	});
};

export const get_unique_values_of_property = (array, property) => {
	let _array = [];
	array.forEach(item => {
		_array.push(item[property]);
	});
	return [...new Set(_array)];
};

export const sort_by = property_name => {
	return (a, b) =>
		a[property_name] < b[property_name]
			? -1
			: a[property_name] > b[property_name]
			? 1
			: 0;
};

export const set_css_variables = variables => {
	for (const [key, value] of Object.entries(variables)) {
		document.getElementById('main-content').style.setProperty(key, value);
	}
};

export const slugify = string => {
	return string
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '')
		.trim();
};

export const sort_object_reversed = (obj, numbers = false) => {
	if (numbers) {
		return Object.keys(obj)
			.sort((a, b) => parseInt(a) - parseInt(b))
			.reverse()
			.map(key => {
				return {
					...obj[key],
					breakpoint: key,
				};
			});
	} else {
		return Object.keys(obj)
			.sort((a, b) => a - b)
			.reverse()
			.map(key => {
				return {
					...obj[key],
					breakpoint: key,
				};
			});
	}
};

export const set_external_links = () => {
	console.log('set_external_links');
	const all_links = document.querySelectorAll('a');
	for (let i = 0; i < all_links.length; i++) {
		const a = all_links[i];
		if (a.hostname !== location.hostname) {
			a.rel = 'noopener';
			a.target = '_blank';
		}
	}
};

export const is_external_link = url => new URL(url).origin !== location.origin;

export const add_satisfi_snippet = popupId => {
	// <script id='satisfiScript' src='https://chat.satis.fi/popup/embedder?popupId=7673'></script>
	let script = document.createElement('script');
	script.id = 'satisfiScript';
	script.src = `https://chat.satis.fi/popup/embedder?popupId=${popupId}`;
	script.setAttribute('script-vue-router-controlled', '');

	document.body.appendChild(script);
};

export const remove_satisfi_snippet = () => {
	let script = document.querySelector('#satisfiScript');
	script.parentNode.removeChild(script);

	let chat = document.querySelector('#divSatisfiChat');
	chat.parentNode.removeChild(chat);
};
