<template>
	<div id="app">
		<main-navigation
			class="component c-navigation position-fixed top-0 left-0 width-100 z-index-1000"
			:class="
				has_min_nav
					? 'c-navigation--min bg-color-white'
					: 'c-navigation--full bg-color-primary'
			"
			section-title="Main Navigation"
			:template="has_min_nav ? 'MainNavigationMin' : 'MainNavigationFull'"
			type="main-navigation"
		/>
		<router-view />
		<main-navigation
			class="component c-navigation c-navigation--footer bg-color-black bp-1024:padding-y-64"
			section-title="Footer Navigation"
			template="MainNavigationFooter"
			type="footer-navigation"
		/>
		<common-footer :terms="footer_links" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MainNavigation from '@/components/navigation/MainNavigation';
import CommonFooter from '@/components/CommonFooter';

export default {
	name: 'App',

	components: {
		MainNavigation,
		CommonFooter,
	},

	data: () => ({
		footer_links: [
			{
				title: 'Terms of Service',
				url: 'https://www.sidearmsports.com/terms-of-service',
			},
			{
				title: 'Privacy Policy',
				url: 'https://www.sidearmsports.com/privacypolicy',
			},
		],
	}),

	methods: {
		...mapActions([
			'fetch_sports',
			'fetch_navigation',
			'show_chat',
			'close_chat',
		]),
		checkChatVisibility(route) {
			if (
				this.is_chat_enabled_for_this_route(
					route.name,
					route.params.slug,
				)
			) {
				this.show_chat();
			} else {
				this.close_chat();
			}
		},
	},

	computed: {
		...mapGetters(['is_chat_enabled_for_this_route']),
		has_min_nav: function() {
			if (this.$route.meta && this.$route.meta.min_nav) {
				return true;
			}
			return false;
		},
		has_championship_nav: function() {
			if (this.$route.meta && this.$route.meta.championship_nav) {
				return true;
			}
			return false;
		},
	},

	watch: {
		$route(to, from) {
			if (to.name !== from.name) {
				if (this.has_min_nav) {
					document.body.classList.add('has-min-nav');
				} else {
					document.body.classList.remove('has-min-nav');
				}

				if (this.has_championship_nav) {
					document.body.classList.add('has-championship-nav');
				} else {
					document.body.classList.remove('has-championship-nav');
				}
			}
			this.checkChatVisibility(to);
		},
	},

	created() {
		this.fetch_sports();
		this.fetch_navigation();
	},
};
</script>

<style lang="scss">
@font-face {
	font-family: 'Legacy';
	src: url('//fonts.sidearmsports.com/custom_fonts/ncaaticket/Legacy-Regular.woff2')
			format('woff2'),
		url('//fonts.sidearmsports.com/custom_fonts/ncaaticket/Legacy-Regular.woff')
			format('woff');
}

.text-brand {
	color: #0088ce;
}

:root {
	/* Background Colors */
	--color-primary: #0088ce;
	--color-primary-hover: #09649b;
	--color-secondary: #002855;
	--color-secondary-hover: #002045;
	--color-default-theme-page: #f6f8fa;
	--color-default-theme-background: #fff;
	--color-default-theme-surface: #fff;
	--color-default-theme-surface-alt: #f6f8fa;

	/* Text Colors */
	--color-text-on-primary: #fff;
	--color-text-on-secondary: #fff;
	--color-default-theme-default-text: #000;
	--color-default-theme-safe-text: #262628;
	--color-default-theme-muted-text: #707275;
	--color-default-theme-brand-text: #0a6fac;
	--color-default-theme-link-text: #0a6fac;
	--color-light-theme-stroke: #d9d9d9;

	/* Stroke Colors */
	--color-default-theme-stroke: #d9d9d9;

	/* Dark Theme Colors */
	--color-dark-theme-page: #000000;
	--color-dark-theme-background: #0f1111;
	--color-dark-theme-surface: #181c1b;
	--color-dark-theme-default-text: #fff;
	--color-dark-theme-safe-text: #ececec;
	--color-dark-theme-muted-text: #d7d7d7;
	--color-dark-theme-brand-text: #fff;
	--color-dark-theme-link-text: #fff;
	--color-dark-theme-stroke: #212625;

	--header-height: 72px;
	--championship-header-height: 72px;
	--full-header-height: var(--header-height);

	--grid-width: 1280px;

	--header-font: 'Legacy';

	@media screen and (min-width: 1024px) {
		--header-height: 105px;
		--championship-header-height: 105px;
	}
}

body {
	background: var(--color-default-theme-page);
	font-family: 'Roboto', Arial, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	margin-top: var(--full-header-height);

	&.has-min-nav {
		--header-height: 48px;

		--full-header-height: var(--header-height);
	}
	&.has-championship-nav {
		--full-header-height: calc(
			var(--header-height) + var(--championship-header-height)
		);
	}
}

.header-font {
	font-family: var(--header-font);
}

.font-legacy {
	font-family: 'Legacy', sans-serif;
}

.max-width-grid {
	max-width: var(--grid-width);
}

.button {
	align-items: center;
	border-radius: 999px;
	display: inline-flex;
	font-family: 'Legacy', sans-serif;
	height: 56px;
	padding: 0 32px;
	text-decoration: none;
	transition: all 0.25s;

	&.--on-dark {
		background-color: var(--color-secondary);
		color: var(--color-text-on-secondary);

		&:hover,
		&:focus {
			background-color: var(--color-secondary-hover);
		}
	}

	&.--on-dark-alt {
		background-color: var(--color-primary);
		color: var(--color-text-on-primary);
		margin-right: 16px;

		&:hover,
		&:focus {
			background-color: var(--color-primary-hover);
		}

		@media screen and (max-width: 600px) {
			width: 100%;
			margin-right: 0px;
		}
	}

	&.--on-light {
		background-color: #edeef0;
		color: #7c7e81;

		&:hover,
		&:focus {
			background-color: var(--color-primary);
			color: var(--color-text-on-primary);
		}
	}

	&.--toggle {
		background-color: #edeef0;
		color: #7c7e81;
		font-family: 'Roboto', sans-serif;
		font-size: 16px;

		&:not(.is-active) {
			&:hover,
			&:focus {
				background-color: darken(#edeef0, 10%);
				color: darken(#7c7e81, 10%);
			}
		}

		&.is-active {
			background-color: var(--color-primary);
			color: var(--color-text-on-primary);
		}
	}

	&.--icon {
		align-items: center;
		border: 1px solid transparent;
		background: var(--color-secondary);
		color: var(--color-text-on-secondary);
		display: flex;
		justify-content: center;
		height: 48px;
		padding: 0;
		width: 48px;

		&:hover,
		&:focus {
			background: var(--color-text-on-secondary);
			border-color: var(--color-default-theme-stroke);
			color: var(--color-secondary);
		}
	}

	&.--expansion {
		align-items: center;
		display: flex;
		justify-content: center;
		height: 48px;
		padding: 0;
		width: 48px;
	}

	&:hover,
	&:focus {
		cursor: pointer;
	}
}

li:nth-child(n + 2) span a.--on-dark-alt {
	@media screen and (max-width: 600px) {
		margin-top: 16px;
	}
}

.section-title {
	color: var(--color-default-theme-brand-text);
	font-family: 'Legacy', sans-serif;
	font-size: 30px;
	line-height: 1;

	@media screen and (min-width: 768px) {
		font-size: 36px;
	}
}

.shadow-level-1 {
	box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
}

.text-shadow {
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.c-slideshow__links {
	display: flex !important;

	@media screen and (max-width: 600px) {
		display: block !important;
	}
}

@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-12px);
	}
	60% {
		transform: translateY(-6px);
	}
}
</style>

<style lang="scss" scoped>
.c-navigation--full,
.c-navigation--min {
	height: var(--header-height);
}
</style>
<style>
[v-cloak] {
	display: none;
}
</style>
