import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueLuxon from 'vue-luxon';
import VueLazyload from 'vue-lazyload';
import setMetaSidearm from '@/mixins/setMetaSidearm';
import { get_image_resizer_url } from '@/helpers';

// Needed to make Swiper 6 work :upside_down_face:
import {
	Swiper as SwiperClass,
	Pagination,
	Navigation,
	Keyboard,
	Autoplay,
	Lazy,
} from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
SwiperClass.use([Pagination, Navigation, Keyboard, Autoplay, Lazy]);
Vue.use(getAwesomeSwiper(SwiperClass));
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
import 'swiper/swiper-bundle.min.css';
////

Vue.use(VueLuxon, {
	input: {
		zone: 'utc',
		format: 'iso',
	},
	output: 'date_short',
});
Vue.use(VueLazyload, {
	lazyComponent: true,
	observer: true,
});

Vue.config.productionTip = false;

Vue.directive('clickOutside', {
	bind: function(el, binding, vnode) {
		el.clickOutsideEvent = function(event) {
			if (!(el == event.target || el.contains(event.target))) {
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent);
	},
	unbind: function(el) {
		document.body.removeEventListener('click', el.clickOutsideEvent);
	},
});

Vue.mixin(setMetaSidearm);

new Vue({
	router,
	store,
	render: h => h(App),
	data: () => ({
		bucket: '//s3.amazonaws.com/ncaatickets.com',
		proxy: 'https://d230gea5qwdu36.cloudfront.net',
		//proxy: 'http://localhost:49329',
		site_title: 'NCAATickets.com',
	}),
	methods: {
		get_image_resizer_url(image_url) {
			return get_image_resizer_url(image_url);
		},
	},
}).$mount('#app');
