import { render, staticRenderFns } from "./MainNavigationFooter.vue?vue&type=template&id=684c50e7&scoped=true&"
import script from "./MainNavigationFooter.vue?vue&type=script&lang=js&"
export * from "./MainNavigationFooter.vue?vue&type=script&lang=js&"
import style0 from "./MainNavigationFooter.vue?vue&type=style&index=0&id=684c50e7&lang=scss&scoped=true&"
import style1 from "./MainNavigationFooter.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684c50e7",
  null
  
)

export default component.exports