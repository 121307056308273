<template>
	<div
		class="c-navigation__container align-items-center display-flex height-100"
	>
		<div
			class="c-navigation__logo display-flex align-items-center margin-r-auto"
		>
			<router-link
				to="/"
				class="display-block padding-x-24 text-decoration-none width-100"
			>
				<img
					class="c-navigation__logo-img display-block"
					src="@/assets/main_logo_small.svg"
					alt="NCAA Tickets Logo"
				/>
			</router-link>
		</div>
		<search />
		<router-link
			class="c-navigation__tickets align-items-center justify-content-center text-transform-uppercase text-decoration-none transition display-none bp-1024:display-flex bp-1024:padding-x-12 bp-1024:margin-x-24 hover:bg-color-primary"
			to="/championships"
		>
			<span class=" font-size-16 header-font">Find Tickets</span>
		</router-link>
		<button
			class="c-navigation__toggle reset height-100 transition"
			:class="mobile_menu_open ? 'is-toggled' : undefined"
			@click.prevent="
				[
					mobile_menu_open ? close_dropdowns() : undefined,
					toggle_mobile_menu(),
				]
			"
		>
			<svg class="hamburger height-100 width-100" viewBox="0 0 100 100">
				<path
					class="line top"
					d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
				/>
				<path class="line middle" d="m 30,50 h 40" />
				<path
					class="line bottom"
					d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
				/>
			</svg>
		</button>
		<div class="c-navigation-mobile height-100">
			<div
				class="c-navigation__offcanvas bg-color-primary position-absolute top-100 right-0 width-100"
				:class="mobile_menu_open ? 'is-open' : undefined"
			>
				<div
					class="c-navigation__offcanvas-inner bp-1024:margin-l-auto bp-1024:position-relative"
				>
					<ul
						class="c-navigation__level-1 reset-list padding-b-72 bp-1024:padding-b-0 bp-1024:display-flex bp-1024:flex-direction-column"
					>
						<li
							class="c-navigation__item--level-1"
							v-for="item in data"
							:key="item.id"
							:class="[
								item.items && item.items.length
									? 'c-navigation__parent'
									: undefined,
								item.is_open ? 'is-open' : undefined,
							]"
						>
							<a
								class="c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-21 justify-content-space-between header-font padding-24 text-on-primary text-decoration-none text-transform-uppercase transition bp-1024:height-100 bp-1024:justify-content-center"
								tabindex="0"
								:aria-label="item.title"
								:aria-haspopup="
									item.items && item.items.length
										? 'true'
										: 'false'
								"
								:aria-expanded="item.is_open ? 'true' : 'false'"
								:title="item.title"
								href="javascript:void(0)"
								@click.prevent="
									item.items && item.items.length
										? toggle_menu_item(item)
										: undefined
								"
								@keyup.enter="
									item.items && item.items.length
										? toggle_menu_item(item)
										: undefined
								"
								v-if="item.items.length"
							>
								<span>{{ item.title }}</span>
								<chevron-down-icon
									class="margin-l-6 transition bp-1024:display-none"
									size="1x"
								/>
							</a>
							<navigation-link
								_class="c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-21  font-weight-500 header-font justify-content-space-between padding-24 text-on-primary text-decoration-none text-transform-uppercase transition bp-1024:height-100 bp-1024:justify-content-center"
								:_aria-label="item.title"
								:_target="
									item.open_in_new_window ? '_blank' : '_self'
								"
								:_title="item.title"
								:url="item.url ? item.url : ''"
								_tabIndex="0"
								v-if="!item.items.length"
							>
								{{ item.title }}
								<chevron-right-icon
									class="transition bp-1024:display-none"
									size="1x"
								></chevron-right-icon>
							</navigation-link>
							<div
								class="c-navigation__level-2 bg-color-surface-alt"
								v-if="item.items.length"
								:class="item.is_open ? 'is-open' : undefined"
							>
								<div
									v-if="
										item.items[0].class_name ===
											'full-width-description'
									"
									class="border-b bg-color-surface text-muted text-align-center padding-24"
									v-html="item.items[0].title"
								></div>
								<ul
									class="reset-list bg-color-surface-alt bp-1024:height-100"
								>
									<li
										class="c-navigation__item--level-2 border-b"
										v-for="_item in item.items"
										v-show="
											_item.class_name !==
												'full-width-description'
										"
										:key="_item.id"
										:class="[
											_item.separator &&
											_item.class_name !==
												'full-width-description'
												? 'c-navigation__separator font-size-18 header-font'
												: 'transition hover:bg-color-primary',
											_item.items && _item.items.length
												? 'display-flex align-items-center'
												: undefined,
										]"
									>
										<navigation-link
											_class="c-navigation__url c-navigation__url--level-2 display-block font-size-18 padding-y-16 padding-x-32 text-black text-decoration-none transition"
											v-if="
												!_item.separator &&
													_item.url != ''
											"
											:url="_item.url ? _item.url : ''"
											:tabindex="
												item.is_open ? '0' : undefined
											"
											>{{ _item.title }}</navigation-link
										>
										<span
											class="display-block font-weight-700 padding-y-16 padding-x-32"
											v-if="_item.separator"
											>{{ _item.title }}</span
										>
										<span
											class="c-navigation__url c-navigation__url--level-2 display-block font-size-16 padding-y-16 padding-x-32 text-black text-decoration-none"
											v-if="
												!_item.separator &&
													_item.url === ''
											"
											>{{ _item.title }}</span
										>
										<ul
											class="c-navigation__level-3 reset-list display-flex align-items-center margin-l-auto padding-r-24"
											v-if="
												_item.items &&
													_item.items.length
											"
										>
											<li
												class="c-navigation__item--level-3"
												v-for="(item,
												index) in _item.items"
												:key="index"
											>
												<navigation-link
													_class="c-navigation__url c-navigation__url--level-3 display-block margin-y-16 margin-x-6 text-align-center text-decoration-none text-on-brand transition"
													v-if="!item.separator"
													:_aria-label="item.title"
													:url="
														item.url ? item.url : ''
													"
													:_tab-index="
														item.is_open
															? '0'
															: undefined
													"
													:_target="
														item.open_in_new_window
															? '_blank'
															: '_self'
													"
													:_title="item.title"
													>{{
														get_gender(item.title)
													}}</navigation-link
												>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ChevronDownIcon, ChevronRightIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/common/NavigationLink';
import Search from '@/components/Search';

export default {
	name: 'MainNavigationMin',

	components: {
		ChevronDownIcon,
		ChevronRightIcon,
		NavigationLink,
		Search,
	},

	props: {
		data: {
			required: true,
			type: Array,
			default: () => [],
		},
	},

	data: () => ({
		mobile_menu_open: false,
	}),

	methods: {
		get_gender(text) {
			if (text === 'Mens') {
				return 'M';
			} else if (text === 'Womens') {
				return 'W';
			} else {
				return '';
			}
		},
		open_mobile_menu() {
			this.mobile_menu_open = true;
		},

		close_mobile_menu() {
			this.mobile_menu_open = false;
		},

		toggle_mobile_menu() {
			this.mobile_menu_open = !this.mobile_menu_open;
		},

		open_menu_item(item) {
			item.is_open = true;
		},

		close_menu_item(item) {
			item.is_open = false;
		},

		toggle_menu_item(item) {
			this.data.forEach(_item => {
				if (_item.id != item.id && _item.is_open) {
					_item.is_open = false;
				}
			});

			item.is_open = !item.is_open;
		},

		close_dropdowns() {
			this.data.forEach(item => {
				if (item.is_open) {
					this.close_menu_item(item);
				}
			});
		},
	},

	watch: {
		$route() {
			this.close_mobile_menu();
			this.close_dropdowns();
		},
	},

	created() {
		this.close_mobile_menu();
		this.close_dropdowns();
	},
};
</script>

<style lang="scss" scoped>
.c-navigation {
	//for naming
	&__logo-img {
		height: 26px;
	}
	&__tickets {
		border: 1px solid var(--color-primary);
		border-radius: 9999px;
		color: var(--color-primary);
		height: 32px;
		flex: 0 0 auto;

		&:hover {
			color: var(--color-text-on-primary);
		}
	}
	&__toggle {
		flex: 0 0 60px;
		outline: none;
		width: 60px;

		&:hover,
		&:focus {
			cursor: pointer;
		}

		.hamburger {
			transition: transform 400ms;
			.line {
				fill: none;
				transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
				stroke: var(--color-primary);
				stroke-width: 3;
				&.top {
					stroke-dasharray: 40 160;
				}
				&.middle {
					stroke-dasharray: 40 142;
					transform-origin: 50%;
					transition: transform 400ms;
				}
				&.bottom {
					stroke-dasharray: 40 85;
					transform-origin: 50%;
					transition: transform 400ms, stroke-dashoffset 400ms;
				}
			}
		}
		&.is-toggled {
			.hamburger {
				transform: rotate(45deg);
				.line {
					&.top {
						stroke-dashoffset: -64px;
					}
					&.middle {
						transform: rotate(90deg);
					}
					&.bottom {
						stroke-dashoffset: -64px;
					}
				}
			}
		}
	}
	&__url[href] {
		&:hover,
		&:focus {
			cursor: pointer;
		}
	}
	&__offcanvas {
		height: 0;
		overflow: hidden;

		&.is-open {
			height: calc(100vh - var(--header-height));
			overflow: auto;
		}
	}

	&__item--level-1 {
		border-bottom: 1px solid #09649b;

		&.is-open {
			.c-navigation__url--level-1 {
				svg {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__parent {
		&.is-open {
			.c-navigation__url--level-1 {
				background: #09649b;
			}
		}
	}

	&__level-2 {
		height: 0;
		overflow: hidden;

		&.is-open {
			height: auto;
			overflow: auto;
		}
	}

	&__item--level-2 {
		&:last-of-type {
			border-bottom: none;
		}
	}
}

@media screen and (min-width: 1024px) {
	.c-navigation {
		//for naming
		&__offcanvas {
			background: linear-gradient(
					0deg,
					rgba(9, 100, 155, 0.15),
					rgba(9, 100, 155, 0.15)
				),
				rgba(0, 0, 0, 0.7);
			height: calc(100vh - var(--header-height));
			opacity: 0;
			overflow: hidden;
			pointer-events: none;
			transition: opacity 0.25s;

			&.is-open {
				opacity: 1;
				overflow: hidden;
				pointer-events: auto;
			}

			&-inner {
				background: var(--color-primary);
				height: 100%;
				opacity: 0;
				transform: translate3d(100%, 0, 0);
				width: 375px;

				.c-navigation__offcanvas.is-open & {
					opacity: 1;
					transition: 0.25s;
					transition-delay: 0.15s;
					transform: translate3d(0, 0, 0);
				}
			}
		}
		&__level-1 {
			background: var(--color-primary);
			height: 100%;
		}
		&__item--level-1 {
			flex: 1 1 auto;
			height: auto;
			opacity: 0;

			.c-navigation__offcanvas.is-open & {
				opacity: 1;
				transition: 0.25s;
				transition-delay: 0.15s;

				@for $i from 1 to 10 {
					&:nth-child(#{$i}) {
						transition-delay: $i * 0.15s;
					}
				}
			}
		}
		&__level-2 {
			height: calc(100vh - var(--header-height)) !important;
			opacity: 0;
			position: absolute;
			top: 0;
			right: 100%;
			width: 0;

			&.is-open {
				opacity: 1;
				overflow-y: auto;
				transition: 0.25s;
				width: 375px;
			}
		}
		&__item--level-2 {
			opacity: 0;
			transform: translateX(24px);
			transition: background 0.25s, opacity 0.25s 0.15s,
				transform 0.25s 0.15s;

			.c-navigation__level-2.is-open & {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}
</style>

<style lang="scss">
.c-navigation--min {
	.c-navigation {
		&__item--level-1 {
			&:hover,
			&:focus {
				.c-navigation__url--level-1 {
					background: #09649b;
				}
			}
		}
		&__item--level-2:not(.c-navigation__separator) {
			&:hover,
			&:focus {
				.c-navigation__url {
					color: var(--color-text-on-primary);
				}
				.c-navigation__url--level-3 {
					border-color: var(--color-text-on-primary);
				}
			}
		}
		&__url--level-3 {
			border: 1px solid var(--color-primary);
			border-radius: 50%;
			color: var(--color-primary);
			height: 32px;
			line-height: 30px;
			width: 32px;
			&:hover,
			&:focus {
				background: var(--color-text-on-primary);
				color: var(--color-primary) !important;
			}
		}
	}
}
</style>
